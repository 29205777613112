<template>
  <div>
    <b-modal hide-footer centered v-model="surveyDisplay.showModal" modal-class="portal-survey" header-border-variant="white">

      <!-- NPS Survey -->
      <b-container fluid v-if="isNPSSurvey" class="nps-survey">
        <b-row class="row p-0 justify-content-end nps-footer-label">
        </b-row>
        <b-row class="p-0 m-0 mt-3" align-h="start">
          <b-col cols="12" class="p-0 pb-3 m-0"><p class="m-0 p-0 mb-3">How likely are you to recommend our site to a friend?</p></b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="text-left nps-footer-label">Less Likely</b-col>
          <b-col cols="6" class="d-none d-sm-block text-right nps-footer-label">More Likely</b-col>
        </b-row>
        <b-form-group>
          <b-form-radio-group size="sm" id="recommend" v-model="survey.NPS.recommend" name="recommend" class="radio-label-vertical">
            <b-row class="p-0 pl-sm-2 m-0" align-h="center">
              <b-col cols="2" sm="1" class="p-0"><div class="icon icon-face icon-sad icon-nps"></div></b-col>
              <b-col cols="10" sm="5" class="pl-sm-4 text-left text-sm-right text-nowrap">
                <b-form-radio v-for="option in recommendOptions1" v-bind:key="option.value" :value="option.value" :class="option.class" class="m-0 mb-5 form-check-radio"><div class="radio-label">{{option.value}}</div></b-form-radio>
              </b-col>
              <b-col cols="10" sm="5" class="p-sm-0 text-right text-sm-left text-nowrap">
                <b-form-radio v-for="option in recommendOptions2" v-bind:key="option.value" :value="option.value" :class="option.class" class="m-0 form-check-radio"><div class="radio-label">{{option.value}}</div></b-form-radio>
              </b-col>
              <b-col cols="2" sm="1" class="p-0"><div class="icon icon-face icon-smile icon-nps"></div></b-col>
            </b-row>
          </b-form-radio-group>
        </b-form-group>
        <b-row class="d-block d-sm-none">
          <b-col cols="12" class="text-right nps-footer-label">More Likely</b-col>
        </b-row>
        <b-row class="p-0 m-0 mt-3" align-h="end">
          <b-col cols="12" class="p-0 m-0"><p class="m-0 p-0 mb-1">Please let us know the reason for your rating</p></b-col>
          <b-form-textarea ref="reason-text-area" v-model="survey.NPS.reason" rows="4" maxLength="1000"></b-form-textarea>
        </b-row>
        <b-row class="p-0 m-0 mt-3 survey-footer" align-h="end">
          <b-col cols="12" class="p-0 m-0">
            <b-button @click="submitSurvey()" variant="primary" size="sm" :disabled="isDisabled">Submit Feedback</b-button>
          </b-col>
        </b-row>
      </b-container>

      <!-- TASK Survey -->
      <b-container fluid v-if="isTASKSurvey" class="task-survey">
        <b-row class="p-0 m-0 mt-3" align-v="center">
          <b-col cols="sm-8" class="p-0"><p class="m-0 p-0 mb-3">How would you rate your experience today?</p></b-col>
            <b-col @click="selectExperience('GOOD')" class="clickable-faces smiling px-8" :class="{active: isExperienceSelected('GOOD')}">
              <span class="icon icon-smile icon-face"></span> <br/> Good
            </b-col>
            <b-col @click="selectExperience('BAD')" class="clickable-faces sad px-8" :class="{active: isExperienceSelected('BAD')}">
              <span class="icon icon-sad icon-face"></span> <br/> Bad
            </b-col>
        </b-row>
        <b-row class="p-0 m-0 mt-3" align-h="end">
          <b-col cols="12" class="p-0 m-0"><p class="m-0 p-0 mb-1">What is the reason for your visit?</p></b-col>
          <b-form-textarea v-model="survey.TASK.reasonForVisit" rows="4" maxLength="1000"></b-form-textarea>
        </b-row>
        <b-row class="p-0 m-0 mt-3" align-v="center">
          <b-col cols="sm-8" class="p-0"><p class="m-0 p-0 mb-3">Were you able to complete your task?</p></b-col>
          <b-col @click="selectTaskCompleted('YES')" class="task-completed yes" :class="{active: isTaskSelected('YES')}">
            <span class="icon" :class="(isTaskSelected('YES')) ? 'icon-checkbox-checked' : 'icon-checkbox-unchecked'"></span> <br /> Yes
          </b-col>
          <b-col @click="selectTaskCompleted('NO')" class="task-completed no" :class="{active: isTaskSelected('NO')}">
            <span class="icon" :class="(isTaskSelected('NO')) ? 'icon-checkbox-checked' : 'icon-checkbox-unchecked'"></span> <br /> No
          </b-col>
        </b-row>
        <b-row class="p-0 m-0 mt-3" align-h="end">
          <b-col cols="12" class="p-0 m-0"><p class="m-0 p-0 mb-1">If you were not able to complete your task today, why not?</p></b-col>
          <b-form-textarea v-model="survey.TASK.ableToCompleteDescription" rows="4" maxLength="1000"></b-form-textarea>
        </b-row>
        <b-row class="p-0 m-0 mt-3 survey-footer" align-h="end">
          <b-col cols="12" class="p-0 m-0">
            <b-button @click="submitSurvey()" variant="primary" size="sm" :disabled="isDisabled">Submit Feedback</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <div v-if="isShowingPrompt" @click="surveyDisplay.showModal = true" id="survey-fixed-box" class="position-fixed">
      <div class="d-flex flex-row-reverse">
        <span></span>
        <div @click.stop="surveyDismissed = true" class="survey-side-close p-2 justify-content-end">X</div>
      </div>
      <p class="header text-center p-0 m-0">Rate our site</p>
      <div class="d-flex justify-content-center icon-container pt-1">
        <span class="icon icon-smile icon-face pr-3"></span>
        <span class="icon icon-sad icon-face"></span>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application';

  const survey_version = 1;

  export default {
    data: function() {
      return {
        surveyTypes: ['NPS', 'TASK'],

        surveyDisplay: {
          showModal: false,
          type: null
        },

        surveySubmitted: 0,
        surveyDismissed: false,

        survey: {
          NPS: {
            recommend: null,
            reason: null
          },

          TASK: {
            experience: null,
            reasonForVisit: null,
            ableToComplete: null,
            ableToCompleteDescription: null
          }
        },

        recommendOptions1: [
          { value: 1, class: 'nps-detractor'},
          { value: 2, class: 'nps-detractor'},
          { value: 3, class: 'nps-detractor'},
          { value: 4, class: 'nps-detractor'},
          { value: 5, class: 'nps-detractor'},
        ],

        recommendOptions2: [
          { value: 6, class: 'nps-detractor'},
          { value: 7, class: 'nps-passive'},
          { value: 8, class: 'nps-passive'},
          { value: 9, class: 'nps-promoter'},
          { value: 10, class: 'nps-promoter double-digit'},
        ],
      }
    },

    watch: {
      surveySubmitted: function(val) {
        localStorage.surveySubmitted = val;
      },
      surveyDismissed: function(val) {
        localStorage.surveyDismissed = val;
      }
    },

    methods: {
      _forceSurvey: function(surveyQueryParam) {
        return (surveyQueryParam && this.surveyTypes.includes(surveyQueryParam.toUpperCase()));
      },

      selectExperience: function(experienceValue) {
        this.survey.TASK.experience = experienceValue;
      },

      isExperienceSelected: function(experienceValue) {
        return (this.survey.TASK.experience===experienceValue);
      },

      selectTaskCompleted: function(taskCompletedValue) {
        this.survey.TASK.ableToComplete = taskCompletedValue;
      },

      isTaskSelected: function(taskCompletedValue) {
        return (this.survey.TASK.ableToComplete===taskCompletedValue);
      },

      submitSurvey: function() {
        this.surveySubmitted = survey_version;
        let payload = {
          surveyType: this.surveyDisplay.type,
          surveyData: this.survey[this.surveyDisplay.type]
        };

        this.surveyDisplay.showModal = false;

        this.$http.post(ApiProperties.api.submitSurvey, payload);
      }
    },

    computed: {
      isDisabled: function() {
        if (this.isNPSSurvey) {
          return !this.survey["NPS"].reason &&
              this.survey["NPS"].recommend === null;
        } else if (this.isTASKSurvey) {
          return this.survey["TASK"].ableToComplete === null &&
              !this.survey["TASK"].ableToCompleteDescription &&
              this.survey["TASK"].experience === null &&
              !this.survey["TASK"].reasonForVisit;
        } else {
          return false;
        }
      },

      isShowingPrompt: function() {
        return this.$root.authStatus.userType === 'EMPLOYEE' &&
                this.surveySubmitted < survey_version &&
                !this.surveyDismissed;
      },

      isNPSSurvey: function() {
        return (this.surveyDisplay.type === this.surveyTypes[0]);
      },

      isTASKSurvey: function() {
        return (this.surveyDisplay.type === this.surveyTypes[1]);
      }
    },

    created: function() {
      if (this._forceSurvey(this.$route.query.survey)) {
        this.surveyDisplay.type = this.$route.query.survey;
        this.surveyDisplay.showModal = true;
      } else {
        this.surveyDisplay.type = this.surveyTypes[Math.floor(Math.random() * this.surveyTypes.length)];
        if (localStorage.surveySubmitted != null) {
          this.surveySubmitted = localStorage.surveySubmitted;
        }
        if (localStorage.surveyDismissed != null) {
          this.surveyDismissed = (localStorage.surveyDismissed === 'true');
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/app";

  .portal-survey {
    color: $surveyFontColor;
    font-size: .8em;

    .close-button {
      cursor: pointer;
    }

    a {
      color: $surveyFontColor;
      font-size: 1.11rem;
    }

    p {
      font-size: 1.11rem;
      font-weight: 700;
    }

    .nps-footer-label {
      margin: -15px 0 0 0;
      color: lightgray;
    }

    .modal-md {
      max-width: 580px;
    }

    .nps-survey {
      .icon-nps {
        font-size: 2em;
        position: relative;
        top: -4px;
        //left: -4px;
      }

      .icon-nps-smile {
        font-size: 2em;
        position: relative;
        top: -30px;
        left: -6px;
      }

      .nps-detractor, .icon-sad {
        color: #e65052;

        input[type=radio] {
          border-color: #e65052;
        }
      }

      .nps-passive {
        color: #efc17d;
      }

      .nps-promoter, .icon-smile {
        color: #05cb05;
      }


      .radio-label-vertical {
        margin-top: 26px;

        .form-check-radio {
          padding-right: 3px;
        }

        .radio-label {
          position:relative;
          top: -26px;
          left: -22px;
        }

        .double-digit {
          div {
            left: -26px !important;
          }
        }
      }
    }

    .task-survey {
      .clickable-faces, .task-completed {
        cursor: pointer;
        font-size: 1rem;
        color: #ccc;
        font-weight: 600;

        .icon {
          font-size: 2rem;
        }

        &.active.smiling, &.active.yes {
          color: #05cb05;
        }

        &.active.sad, &.active.no {
          color: #e65052;
        }

      }
    }

    .survey-footer {
      font-size: 1.11rem;
    }
  }

  #survey-fixed-box {
    background-color: #66bbe6;
    right: 0;
    top: 65%;
    z-index: 2;
    border-radius: 10px 0 0 10px;
    width: 10rem;
    height: 7rem;
    color: #fff;
    cursor: pointer;

    p.header {
      font-weight: 700;
      font-size: 1.1rem;
    }

    .survey-side-close {
      font-weight: 900;
      font-size: 1.05rem;
      line-height:1rem;
      color: #fff;
      opacity: .8;
    }

    .icon-container {
      .icon-face {
        font-size: 1.9rem;
        opacity: .7;
      }
    }
  }
</style>