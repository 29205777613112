import axios from 'axios';
import get from 'just-safe-get'

//curried method to get default params from main.js
const onFulfilledRequestInterceptor = (csrfFunction, defaultPathVars) => ( (config) => {
  // start the nprogress bar
  if (vueInstanceFunction()) {
    vueInstanceFunction().nprogress.start();
  }

  // Do something before request is sent
  let url = config.url;

  let allVars = Object.assign({}, defaultPathVars);
  allVars = Object.assign(allVars, config.pathVars);

  if (url.includes('{')) {
    //Automatic defaults to be replaces
    for (const pathVar of Object.keys(allVars)) {
      let value = allVars[pathVar];
      if (typeof value === 'function') {
        value = value();
      }
      url = url.replace(`{${pathVar}}`, value);
    }

    //set the final url with all pathVars replace
    config.url = url;
  }

  if (config.method !== 'get') {
    config.headers['X-CSRF-TOKEN'] = csrfFunction();
  }

  return config;
});

const onFulfilledResponseInterceptor = (response) => {
  // stop the nprogress
  if (vueInstanceFunction()) {
    vueInstanceFunction().nprogress.done();
  }

  return response;
};

const onRejectedResponseInterceptor = (error) => {
  let statusCode = get(error, "response.status")

  // Do something with response error types
  if ([401, 403].includes(statusCode)) {
    vueInstanceFunction().clearState()
  }

  if ([408].includes(statusCode)) {
    vueInstanceFunction().navigateToMaintenance()
  }

  if (!statusCode || parseInt(statusCode) >= 500) {
    //this will be an error page or something that handles server error (maybe a toast of some sort as a standard error response in the api handler)
  }

  vueInstanceFunction().nprogress.done();
  return Promise.reject(error);
};

let vueInstanceFunction = null;

export default {
  axiosInstance: null,

  // this is a method so we can expand on this later (i.e. headers, etc..)
  setAxiosInstance: function(baseURL, withCredentials) {
    this.axiosInstance = axios.create({
      baseURL,
      withCredentials
    });

    return this;
  },

  initAxiosInterceptors: function(csrfFunction, defaultPathVars, vueFunction) {
    this.axiosInstance.interceptors.response.use(onFulfilledResponseInterceptor, onRejectedResponseInterceptor);
    this.axiosInstance.interceptors.request.use(onFulfilledRequestInterceptor(csrfFunction, defaultPathVars));
    vueInstanceFunction = vueFunction;
    return this;
  }
}
