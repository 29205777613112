export default {
  getFileSizeInBytes: function(propertyString) {
    const ACCEPTED_LABELS = ['KB','MB','GB'];

    // extract numbers
    let fileSizeAsNumber = propertyString.match(/\d+/g).map(Number);

    // extract string
    let fileLabel = propertyString.match(/[a-zA-Z]+/g).toString().toUpperCase();

    // if we used a text label, return the value in bytes
    if (fileLabel!==null && fileLabel!==undefined && ACCEPTED_LABELS.includes(fileLabel)) {
      switch (fileLabel) {
        case 'KB' : return 1024 * fileSizeAsNumber;
        case 'MB' : return 1024 * 1024 * fileSizeAsNumber;
        case 'GB' : return 1024 * 1024 * 1024 * fileSizeAsNumber;
      }
    }

    // return the number value as bytes
    return fileSizeAsNumber;
  }
}