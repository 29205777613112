import ApiProperties from '../../../src/main/resources/application'
import Constants from '../../../src/main/resources/constants'
import get from 'just-safe-get'

const defaultLarkinAdmin = {
  fetched: false,
  firstName: null,
  lastName: null,
  username: null,
  email: null,
  timeZone: null,
  assignmentStatus: null,
  phone: Constants.mainPhoneNumber,
  url: Constants.phoneUrlPrefix + Constants.mainPhoneNumber,
  contacts: [],
  comment: null,
  avatar: "https://assets.thelarkincompany.com/assets/admin/admin-icon-user.png"
}

export default {
  data: function() {
    return {
      leaveAdmin: Object.assign({}, defaultLarkinAdmin),
      errors: []
    }
  },

  mixins: [],

  methods: {
    resetLeaveAdmin: function(rootData) {
      this.leaveAdmin = Object.assign({}, defaultLarkinAdmin)

      rootData.leaveAdmin = this.leaveAdmin
    },

    setLeaveAdminInfo: function(rootData) {

      //TODO: create user object to pull lastVisitedLeaveId and other user attributes
      let lastVisitedLeaveId = null;

      let requestUrl = (lastVisitedLeaveId) ? ApiProperties.api.leaveAdminInfoByLeave : ApiProperties.api.leaveAdminInfoByPortalUserId;
      let pathParams = (lastVisitedLeaveId) ? {pathVars: {leaveId: lastVisitedLeaveId}} : null;

      this.$http.get(requestUrl, pathParams).then(response => {
        this.leaveAdmin = response.data;
        this.leaveAdmin.fetched = true;
        this.pageViewInit();

        rootData.leaveAdmin = this.leaveAdmin
      }).catch(e => {
        this.errors.push(e);
      });
    },

    formatPhoneNumber: function(unformattedPhone) {
      let digitsOnly = unformattedPhone.replace(/[^0-9]/g,'');
      if (digitsOnly.length !== 10 || unformattedPhone.match(/[^0-9 ()-]/)) {
        return unformattedPhone;
      } else {
        return digitsOnly.substring(0, 3) + "-" +
          digitsOnly.substring(3, 6) + "-" +
          digitsOnly.substring(6);
      }
    },

    getHeadshotImageSrc: function() {
      return (this.leaveAdmin && this.leaveAdmin.avatar)
        ? this.leaveAdmin.avatar
        : "https://assets.thelarkincompany.com/assets/admin/admin-icon-user.png";
    },

    getComment: function() {
      let name = (this.leaveAdmin && this.leaveAdmin.firstName) ? this.leaveAdmin.firstName : "your Program Administrator";
      return (this.leaveAdmin && this.leaveAdmin.comment)
        ? this.leaveAdmin.comment
        : `Hi, I'm ${name}, and I'm always here to help you get your questions answered! My goal is to make the leave process as simple as possible for you.`
    },

    getContactInfo: function(number) {
      if (number === undefined || number === ""){
        return [{phone: Constants.localPhoneNumber, url: Constants.phoneUrlPrefix + Constants.localPhoneNumber},
                {phone: Constants.mainPhoneNumber, url: Constants.phoneUrlPrefix + Constants.mainPhoneNumber}];
      }else{
        number = this.formatPhoneNumber(number);
        return {
          phone: number,
          url: Constants.phoneUrlPrefix + number
        }
      }
    },

    pageViewInit: function() {
      this.leaveAdmin.avatar = this.getHeadshotImageSrc();
      this.leaveAdmin.comment = this.getComment();
      this.leaveAdmin.contacts = [];

      if ((get(this.leaveAdmin, "adminDirectContacts.0.phone")===undefined) || get(this.leaveAdmin, "adminDirectContacts.0.phone")==="") {
        this.getContactInfo().forEach(x => {this.leaveAdmin.contacts.push(x)})
      } else {
        if (this.leaveAdmin.adminDirectContacts) {
          for (let contact of this.leaveAdmin.adminDirectContacts) {
            this.leaveAdmin.contacts.push(this.getContactInfo(contact.phone))
          }
        } else {
          this.leaveAdmin.contacts.push(this.getContactInfo(this.leaveAdmin.phone))
        }
      }
    }
  }
}