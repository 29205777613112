import get from "just-safe-get";

export default {
    install: (Vue, options) => {
        Vue.prototype.$constants = {
            UserIntent: {
                values: {
                    CLIENT: {userType: "CLIENT", path: "employer" },
                    HRBP: {userType: "HRBP", path: null },
                    PAYROLL: {userType: "PAYROLL", path: null},
                    EE_MANAGER: {userType: "EE_MANAGER", path: null},
                    IT_SECURITY: {userType: "IT_SECURITY", path: null},
                    EMPLOYEE: {userType: "EMPLOYEE", path: "employee"},
                    AUTHENTICATED: {userType: "AUTHENTICATED", path: null},
                    GUEST: {userType: "GUEST", path: null},
                },

                getByUserType: function(userType) {
                    return this.values[Object.keys(this.values).find(key => this.values[key].userType===userType)]
                },

                getByPath: function(path) {
                    return this.values[Object.keys(this.values).find(key => this.values[key].path===path)]
                }
            },

            LoginRouteNames: {
                EMPLOYEE: 'loginEmployee',
                CLIENT: 'loginClient'
            },

            DateUnavailablePlaceholder: "TBD",

            HistoryContext: {
                WITH_PORTALFILE_HISTORY: "WITH_PORTALFILE_HISTORY",
                WITH_STREAMING_PORTALFILE_HISTORY: "WITH_STREAMING_PORTALFILE_HISTORY",
                WITH_PORTALFILE_STATECHANGE: "WITH_PORTALFILE_STATECHANGE",
                WITH_NOTIFICATION_HISTORY: "WITH_NOTIFICATION_HISTORY",
            }
        }
    }
}