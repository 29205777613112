const videos = [
  {
    title: "Welcome to Larkin",
    description: "Let's go over what you can do on the Larkin Portal, starting with its different sections.",
    url: "https://www.youtube-nocookie.com/embed/Anh0vNhEJqA?modestbranding=1"
  },
  {
    title: "Getting Started",
    description: "You're now ready to learn about your steps to getting started.",
    url: "https://www.youtube-nocookie.com/embed/RD9jPddBJXY?modestbranding=1"
  },
  {
    title: "Getting in Touch",
    description: "Our goal is to make the leave process as simple as possible for you.",
    url: "https://www.youtube-nocookie.com/embed/auWnmx5tlZw?modestbranding=1"
  }
];

export default {
  data: function() {
    return {
      videos: videos
    }
  }
};
