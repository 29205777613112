import {routing} from '@/router'

export default {
    data: function() {
        return {}
    },

    methods: {
        navigationAllowed(routeName) {
            return routing.navigationAllowed(routeName, this.$root)
        }
    }
}
