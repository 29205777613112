<template>
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1175 4.202C18.8299 4.47132 18.8299 4.90799 19.1175 5.17731L22.1306 8L19.1175 10.8227C18.8299 11.092 18.8299 11.5287 19.1175 11.798C19.405 12.0673 19.8711 12.0673 20.1586 11.798L23.6923 8.48766C23.9798 8.21833 23.9798 7.78167 23.6923 7.51234L20.1586 4.202C19.8711 3.93267 19.405 3.93267 19.1175 4.202Z" fill="#0E1F22"/>
        <path d="M0.736196 1.45455H15.1656C15.5337 1.45455 15.9018 1.13636 15.9018 0.772727C15.9018 0.409091 15.5337 0 15.1656 0H0.736196C0.322086 0 0 0.363636 0 0.727273C0 1.09091 0.322086 1.45455 0.736196 1.45455ZM15.1656 7.27273H0.736196C0.322086 7.27273 0 7.63636 0 8C0 8.40909 0.322086 8.72727 0.736196 8.72727H15.1656C15.5337 8.72727 15.9018 8.40909 15.9018 8C15.9018 7.63636 15.5337 7.27273 15.1656 7.27273ZM15.1656 14.5455H0.736196C0.322086 14.5455 0 14.9091 0 15.2727C0 15.6364 0.322086 16 0.736196 16H15.1656C15.5337 16 15.9018 15.6818 15.9018 15.3182C15.9018 14.9545 15.5337 14.5455 15.1656 14.5455Z" fill="#0E1F22"/>
    </svg>
</template>

<script>
export default {
  name: 'HamCompressed',
}

</script>