<template>
    <a :href="$root.larkinContact.url">{{$root.larkinContact.phone}}</a>
</template>

<script>
    import Constants from '../../../src/main/resources/constants'

    export default {
        name: 'ClickToCallMain'
    }
</script>
