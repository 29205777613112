import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import EmptyRouterView from './views/EmptyRouterView.vue'
import get from 'just-safe-get'
import Constants from './plugins/constants'
import {root} from "vue-browser-detect-plugin/.eslintrc";

Vue.use(Router);
Vue.use(Constants);

/**
 * Summary:
 *
 * 1. History mode which will rewrite or use the browsers history (back/forward buttons)
 *
 * 2. Route Level Code Splitting:
 * We will lazy load all the routes so we dont pull all the pages in at once. This means that webpack will generate
 * a JS chunk (hashed) for each page. (i.e. toDoHome.[hash].js) and lazy load that when hitting that page.
 *
 */

/**
 * REFRESHED LOOK AND FEEL: Added the ability to add parentClass <optional> to router meta info to apply new look and feel styles.
 *
 * TODO: This will ultimately be removed and the entire site will take on the new look and feel
 */

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      showNavLinks: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      applyNewLook: true,
      title: "Hello {firstName}",
      showNavLinks: true,
      canShowSurvey: true,
      scrollToTop: true,
      navigationAllowed: isEmployee
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: {
      title: "Site Maintenance",
      scrollToTop: true,
      canShowSurvey: false
    },
    component: () => import ('./views/Maintenance.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    meta: {
      title: "Terms Of Service",
      scrollToTop: true,
      canShowSurvey: true,
      showNavLinks: true
    },
    component: () => import ('./views/TermsOfService.vue'),
  },
  {
    path: '/benchmarking',
    name: 'benchmarking',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      title: "Benchmarking",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isClient
    },
    component: () => import('./views/Benchmarking.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    props: route => ({
      breadcrumb: {
        icon: 'icon-search2',
        items: [
          { text: 'Dashboard', active: true }
        ]
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Dashboard",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isClient
    },
    component: () => import('./views/Dashboard.vue'),
  },
  {
    path: '/employees',
    name: 'employees',
    props: route => ({
      breadcrumb: {
        icon: 'icon-search2',
        items: [
          { text: 'Find Employee', active: true }
        ]
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Employees",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isClient
    },
    component: () => import('./views/Employees.vue'),
  },
  {
    path: '/employees/:employeeId',
    name: 'employeeDetail',
    props: route => ({
      employeeId: route.params.employeeId,
      breadcrumb: {
        icon: 'icon-search2',
        items: [
          { text: 'Find Employee', to: { name: 'employees' } },
          { text: 'Employee Profile', active: true }
        ]
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Employee Detail",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isClient
    },
    component: () => import('./views/EmployeeDetail.vue'),
  },
  {
    path: '/employees/:employeeId/leave/:leaveId',
    name: 'employeeLeaveDetail',
    props: route => ({
      employeeId: route.params.employeeId,
      leaveId: route.params.leaveId,
      breadcrumb: {
        icon: 'icon-search2',
        items: [
          { text: 'Find Employee', to: { name: 'employees' } },
          { text: 'Employee Profile', to: { name: 'employeeDetail', params: { employeeId: route.params.employeeId }} },
          { text: 'Leave Details', active: true }
        ]
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Leave Detail",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isClient
    },
    component: () => import('./views/EmployeeLeaveDetail.vue'),
  },
  {
    path: '/active-leaves/detail/:employeeId/:leaveId',
    name: 'activeLeaveEmployeeLeaveDetail',
    props: route => ({
      employeeId: route.params.employeeId,
      leaveId: route.params.leaveId,
      breadcrumb: {
        icon: 'icon-search2',
        items: [
          { text: 'Active Leaves', to: { name: 'activeLeaves' } },
          { text: 'Leave Details', active: true }
        ]
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Leave Detail",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isAnyEmployerUser
    },
    component: () => import('./views/EmployeeLeaveDetail.vue'),
  },

  {
    path: '/dashboard/:employeeId/leave/:leaveId',
    name: 'dashboardLeaveDetail',
    props: route => ({
      employeeId: route.params.employeeId,
      leaveId: route.params.leaveId,
      breadcrumb: {
        icon: 'icon-search2',
        items: [
          { text: 'Dashboard', to: { name: 'dashboard' } },
          { text: 'Employee Profile', to: { name: 'employeeDetail', params: { employeeId: route.params.employeeId }} },
          { text: 'Leave Details', active: true }
        ]
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Leave Detail",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isClient
    },
    component: () => import('./views/EmployeeLeaveDetail.vue'),
  },
  {
    path: '/active-leaves/:employeeId?',
    name: 'activeLeaves',
    props: route => ({
      employeeId: route.params.employeeId,
      breadcrumb: {
        icon: 'icon-search2',
        items: []
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Active Leaves",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isAnyEmployerUser
    },
    component: () => import('./views/ActiveLeaves.vue')
  },
  {
    path: '/payment',
    name: 'paymentVouchers',
    props: route => ({
      breadcrumb: {
        icon: 'icon-search2',
        items: [
          { text: 'Dashboard', to: { name: 'dashboard' } },
          { text: 'Payment vouchers', active: true }
        ]
      }
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      applyNewLook: true,
      title: "Payments",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isPayrollWithPayments
    },
    component: () => import('./views/PaymentVouchers.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      title: "Reports",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isPayroll
    },
    component: () => import('./views/Reports.vue'),
  },
  {
    path: '/compliance',
    name: 'compliance',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      title: "Compliance",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: rootData => isClient(rootData) && isShowingCompliance(rootData)
    },
    component: () => import('./views/Compliance.vue'),
  },
  {
    path: '/report/:reportId',
    name: 'report',
    props: route => ({
      reportId: route.params.reportId
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.CLIENT,
      title: "Report",
      scrollToTop: true,
      showNavLinks: true,
      canShowSurvey: false,
      navigationAllowed: isClient
    },
    component: () => import('./views/Report.vue'),
  },
  {
    path: '/initialize/:token',
    name: 'validateInitializeToken',
    props: route => ({
      token: route.params.token,
      tokenType: 'INITIALIZE'
    }),
    meta: {
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest,
      title: "validate token"
    },
    component: () => import('./views/ValidateToken.vue'),
  },
  {
    path: '/initializeAccount/:token',
    name: 'oldValidateInitializeToken',
    props: route => ({
      token: route.params.token,
      tokenType: 'INITIALIZE'
    }),
    meta: {
      title: "Initialize Account",
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest
    },
    component: () => import('./views/ValidateToken.vue'),
  },
  {
    path: '/identity/validate',
    name: 'validateIdentity',
    meta: {
      title: 'Verify it\'s you',
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest
    },
    component: () => import('./views/ValidateIdentity.vue'),
  },
  {
    path: '/identity/:identityId/verify-email',
    name: 'verifyIdentityByEmail',
    props: route => ({
      identityId: route.params.identityId,
      verificationMethod: 'EMAIL'
    }),
    meta: {
      title: 'Account Validation',
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest
    },
    component: () => import('./views/VerifyIdentity.vue'),
  },
  {
    path: '/identity/:identityId/verify-sms',
    name: 'verifyIdentityBySMS',
    props: route => ({
      identityId: route.params.identityId,
      verificationMethod: 'SMS',
      phoneLastFourDigits: route.params.phoneLastFourDigits
    }),
    meta: {
      title: 'Account Validation',
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest
    },
    component: () => import('./views/VerifyIdentity.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'validateResetToken',
    props: route => ({
      token: route.params.token,
      tokenType: 'RESET_PASSWORD'
    }),
    meta: {
      title: "Reset Password",
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest
    },
    component: () => import('./views/ValidateToken.vue'),
  },
  {
    path: '/login',
    name: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
    redirect: '/login/employee',
    meta: {}
  },
  {
    path: '/login',
    name: Vue.prototype.$constants.LoginRouteNames.CLIENT,
    redirect: '/login/employer',
    meta: {}
  },
  {
    path: '/login/:intent',
    name: 'login',
    props: route => ({
      intent: route.params.intent
    }),
    meta: {
      title: "Account Sign In",
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest
    },
    component: () => import('./views/Login.vue'),
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    meta: {
      title: "Reset Password",
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isGuest
    },
    component: () => import('./views/ResetPassword.vue'),
  },
  {
    path: '/initialize-account',
    name: 'initializeAccount',
    meta: {
      title: 'New Account Setup',
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isLoggedIn
    },
    component: () => import('./views/InitializeAccount.vue')
  },
  {
    path: '/set-password',
    name: 'setPassword',
    meta: {
      applyNewLook: true,
      hideGlobalElements: true,
      navigationAllowed: isLoggedIn,
      scrollToTop: true,
      canShowSurvey: false,
      title: "Password Reset"
    },
    component: () => import('./views/SetPassword.vue')
  },
  {
    path: '/expired-password',
    name: 'expiredPassword',
    meta: {
      canShowSurvey: false,
      navigationAllowed: isLoggedIn
    },
    component: () => import('./views/ChangePassword.vue')
  },
  {
    path: '/multifactor-code',
    name: 'multifactorCode',
    meta: {
      title: "Validation",
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isLoggedIn
    },
    component: () => import('./views/MultifactorCode.vue')
  },
  {
    path: '/todo',
    name: 'todo',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      title: "To Do List",
      applyNewLook: true,
      showNavLinks: true,
      canShowSurvey: true,
      navigationAllowed: isEmployee
    },
    props: route => ({
      defaultAction: route.query.defaultAction,
      taskId: route.query.taskId
    }),
    component: () => import('./views/TodoList.vue')
  },

  {
    path: '/payments',
    name: 'payments',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      applyNewLook: true,
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      title: 'Payments',
      navigationAllowed: isEmployeeWithPayment
    },
    component: () => import('./views/Payments.vue')
  },
  {
    path: '/payments/pay-process',
    name: 'payments-pay-process',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      applyNewLook: true,
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      title: 'Pay Process',
      navigationAllowed: isEmployee
    },
    component: () => import('./views/PayProcess.vue')
  },
  {
    path: '/payments/leave-examples',
    name: 'payments-leave-examples',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      applyNewLook: true,
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      title: 'Leave Examples',
      navigationAllowed: isEmployee
    },
    component: () => import('./views/LeaveExamples.vue')
  },
  {
    path: '/payments/:paymentId',
    name: 'paymentDetail',
    props: route => ({
      paymentId: route.params.paymentId
    }),
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      title: 'Pay Schedule',
      navigationAllowed: isEmployee
    },
    component: () => import('./views/PaymentDetail.vue')
  },
  {
    path: '/leaves',
    name: 'leaves',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      title: 'Calendar',
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      navigationAllowed: isEmployeeWithLeave
    },
    component: () => import('./views/Leaves.vue')
  },
  {
    path: '/claims',
    name: 'claims',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      title: 'Claims',
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      navigationAllowed: isEmployeeWithClaim
    },
    component: () => import('./views/Claims.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      title: 'Documents',
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      navigationAllowed: isEmployee
    },
    component: () => import('./views/Documents.vue')
  },
  {
    path: '/profile',
    name: 'employeeProfile',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      title: 'Profile',
      showAdminFooter: true,
      showNavLinks: true,
      scrollToTop: true,
      canShowSurvey: true,
      navigationAllowed: isEmployee
    },
    children: [
      {
        path: '/profile/password',
        name: 'changePassword',
        meta: {
          title: 'Profile',
          showAdminFooter: true,
          showNavLinks: true,
          canShowSurvey: false,
          navigationAllowed: isLoggedIn,
        },
        component: () => import('./views/ChangePassword.vue')
      }
    ],
    component: () => import('./views/EmployeeProfile.vue')
  },
  {
    path: '/landing',
    name: 'emailToPortalLanding',
    meta: {
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false
    },
    component: () => import('./views/Landing.vue'),
  },
  {
    path: '/question-response',
    name: 'questionResponse',
    meta: {
      scrollToTop: true,
      canShowSurvey: false
    },
    component: () => import('./views/QuestionResponse.vue'),
  },
  {
    path: '/workflow/success',
    name: 'workflowSuccess',
    meta: {
      loginRouteName: Vue.prototype.$constants.LoginRouteNames.EMPLOYEE,
      title: 'Workflow Success',
      applyNewLook: true,
      hideGlobalElements: true,
      scrollToTop: true,
      canShowSurvey: false,
      navigationAllowed: isEmployee
    },
    component: () => import('./views/WorkflowSuccess.vue')
  },
];

function getAllRoutes(routes, allRoutes) {
  routes.reduce((arr, route) => {
    arr.push(route);

    if (route.children) {
      getAllRoutes(route.children, arr);
    }
    return arr;
  }, allRoutes);
  return allRoutes;
}

const routesByName = (() => {
  return getAllRoutes(routes, []).reduce((obj, route) => {
    if (route.name) {
      obj[route.name] = route;
    }
    return obj;
  }, {});
})();

function getAuthRedirectRoute(rootData) {
  switch (rootData.authStatus.authTypeRequired) {
    case 'PROVIDE_2FA_CODE': return routesByName.multifactorCode;
    case 'UPDATE_PASSWORD': {
      switch (rootData.authStatus.state) {
        case 'INITIALIZE': return routesByName.initializeAccount;
        case 'RESET_REQUIRED': return routesByName.setPassword;
        default: return routesByName.expiredPassword;
      }
    }
  }
}

function isFullyAuthenticated(rootData) {
  return (rootData.authStatus.authTypeRequired==='NONE')
}

function isFullyAuthenticatedEmployee(rootData) {
  return (isFullyAuthenticated(rootData) && isEmployee(rootData))
}

function isUserType(rootData, allowedUserTypes) {
  return allowedUserTypes.includes(get(rootData, 'authStatus.userType'))
}

function isEmployee(rootData) {
  return isUserType(rootData, ['EMPLOYEE']);
}

function isEmployeeWithPayment(rootData) {
  return isEmployee(rootData) && get(rootData, 'user.hasPayment', false)
}

function isEmployeeWithLeave(rootData) {
  return isEmployee(rootData) && get(rootData, 'user.hasLeave', false)
}

function isEmployeeWithClaim(rootData) {
  return isEmployee(rootData) && get(rootData, 'user.hasClaim', false)
}

function isClient(rootData) {
  return isUserType(rootData, ['CLIENT']);
}

function isPayroll(rootData) {
  return isUserType(rootData, ['CLIENT','PAYROLL']);
}

function isEmployeeManagerOrAbove(rootData) {
  return isUserType(rootData, ['CLIENT','EE_MANAGER']);
}

function isEmployeeManagerOnly(rootData) {
  return isUserType(rootData, ['EE_MANAGER']);
}

function isAnyEmployerUser(rootData) {
  return isUserType(rootData, ['CLIENT','EE_MANAGER','PAYROLL','HRBP','IT_SECURITY'])
}

function isPayrollWithPayments(rootData) {
  return isPayroll(rootData) && isShowingPayments(rootData)
}

function isShowingCompliance(rootData) {
  return rootData.user.complianceClient;
}

function isShowingPayments(rootData) {
  let disabilityClient = rootData.user.disabilityClient;
  return disabilityClient;
}

function isLoggedIn(rootData) {
  return isUserType(rootData, ['EMPLOYEE', 'CLIENT', 'HRBP', 'PAYROLL', 'EE_MANAGER','IT_SECURITY']);
}

function isGuest(rootData) {
  return isUserType(rootData, ['GUEST']);
}

/**
 * Getting the deep link intended route:
 *
 * @param rootData
 * @returns {*}
 */
function getIntendedRouteIfValid(rootData) {
  const intendedRouteNavigationAllowed = get(rootData, 'intendedRoute.meta.navigationAllowed', ()=>{return false})
  const intendedRoute = (rootData.intendedRoute && intendedRouteNavigationAllowed(rootData)) ? rootData.intendedRoute : null

  //clear out intended route object
  rootData.intendedRoute = null

  return intendedRoute
}

function getDefaultRoute(rootData, loginRouteName) {
  //we will return the auth redirect first before the intended route as that means we are still in the 2FA login process, password change, or something else login related
  const authRedirect = getAuthRedirectRoute(rootData);
  if (authRedirect) return authRedirect;

  let intendedRoute = getIntendedRouteIfValid(rootData)

  //removed all this redirect business as we are no longer writing to localStorage for the birth confirmation redirect
  switch (rootData.authStatus.userType) {
    case 'EMPLOYEE': {
      return intendedRoute || routesByName.home;
    }
    case 'CLIENT': {
      return intendedRoute || routesByName.dashboard;
    }
    case 'PAYROLL': {
      return intendedRoute || routesByName.paymentVouchers;
    }
    case 'EE_MANAGER': {
      return intendedRoute || routesByName.activeLeaves
    }
    case 'IT_SECURITY': {
      return intendedRoute || routesByName.activeLeaves
    }
    case 'HRBP': {
      return intendedRoute || routesByName.activeLeaves
    }
  }

  //if there are no redirects, we know the user needs to login and if we know the proper intent page, then use it
  return (loginRouteName) ? routesByName[loginRouteName] : routesByName.loginEmployee
}

function navigationAllowed(routeName, rootData) {
  return routesByName[routeName].meta.navigationAllowed(rootData);
}

let scrollBehavior = function(to, from, savedPosition) {
  // if we have a scrollToTop entry in the route meta object
  let scrollToTop = get(to, 'meta.scrollToTop');

  // otherwise, we leave the scroll in place
  if (scrollToTop) {
    return { x: 0, y: 0 }
  }
};

const routing = {
  routes: routes,
  getDefaultRoute: getDefaultRoute,
  navigationAllowed: navigationAllowed,
  routesByName,
  isEmployee,
  isFullyAuthenticatedEmployee
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior: scrollBehavior
})

export { router, routing };
