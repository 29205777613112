<template>
  <b-container fluid>
    <b-row class="leave-specialist text-left">
      <b-col cols="12" order="1">
        <h4>Your Program Administrator</h4>
      </b-col>


      <b-col order="2" class="mb-3">
        <b-row align-v="center" class="align-middle">
          <b-col cols="2" class="headshot">
            <img border="0" class="responsive-image" alt="Leave Admin" :src="$root.leaveAdmin.avatar || '/img/admin-avatar.png' " />
          </b-col>
          <b-col class="name-title p-0 ml-2">
            <h6 class="title">{{$root.leaveAdmin.firstName || "Your Program Administrator"}} {{$root.leaveAdmin.lastName}}</h6>
            <b-row align-v="baseline" class="contact-info leave-admin-contact-info" no-gutters>
              <p class="m-0 mr-1" for="admin-contact-phone">Phone: </p>
              <b-col class="contact-data p-0" id="admin-contact-phone">
                <div v-for="(contact, index) in $root.leaveAdmin.contacts" :key="index">
                  <p v-if="index === 0 && $root.leaveAdmin.extension" class="m-0 mr-1"> <a :href="contact.url">{{contact.phone}}</a> Ext. {{$root.leaveAdmin.extension}}</p>
                  <p v-else-if="index === 0" class="m-0 mr-1"> <a :href="contact.url">{{contact.phone}}</a></p>
                  <p v-else class=" m-0"> <a :href="contact.url">{{contact.phone}}</a> </p>
                </div>
              </b-col>
            </b-row>

            <b-row align-v="baseline" class="contact-info leave-admin-contact-info">
              <p class="m-0 mr-1" for="admin-contact-email">Email:</p>
              <p class="m-0"> <a :href="`mailto:${$root.leaveAdmin.email}`">{{$root.leaveAdmin.email || "https://google.com"}}</a></p>
            </b-row>

            <b-row align-v="baseline" class="contact-info leave-admin-contact-info">
              <p class="m-0 mr-1" for="admin-contact-hours">Hours:</p>
              <p class="m-0" v-if="$root.leaveAdmin.timeZone === -8"> 8 AM - 5 PM PST (M-F)</p>
              <p class="m-0" v-else-if="$root.leaveAdmin.timeZone === -7"> 8 AM - 5 PM MST (M-F)</p>
              <p class="m-0" v-else-if="$root.leaveAdmin.timeZone === -6"> 8 AM - 5 PM CST (M-F)</p>
              <p class="m-0" v-else-if="$root.leaveAdmin.timeZone === -5"> 8 AM - 5 PM EST (M-F)</p>
              <p class="m-0" v-else> 8 AM - 5 PM PST (M-F)</p>
            </b-row>

            <b-row v-if="$root.leaveAdmin.meetingUrl" align-v="baseline" class="contact-info leave-admin-contact-info mt-3">
              <b-btn :href="$root.leaveAdmin.meetingUrl" target="_blank" class="btn-larkin-yellow btn-padded"><span class="button-text font-weight-bold" style="font-size: 14px;">Schedule a Meeting<i class="icon icon-calendar ml-2"></i> </span></b-btn>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="comment-col" cols="12" order="5">
        <p v-if="!$root.leaveAdmin.meetingUrl" class="card-text comment">"{{$root.leaveAdmin.comment || "Hi, I'm your program administator, and I'm always here to help you get your questions answered! My goal is to make the leave process as simple as possible for you."}}"</p>
      </b-col>

      <b-col class="comment-col" cols="12" order="5">
        <p class="card-text warning" v-html="OOOMessage"></p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  export default {
    name: 'LeaveSpecialistInfo',
    data: function() {
      return {}
    },
    computed: {
      OOOMessage() {
        let ads = this.$root.leaveAdmin.assignmentStatus;
        let bks = this.$root.user.backupAdminStatus;
        let ov = "OUT_OF_OFFICE"
        let ms;
        if (ads === ov && bks === ov || (ads === ov && bks === undefined)){
          ms = "I'm currently out of office, please contact our office at 866-923-3336 for assistance."
        }
        else if (ads === ov && bks !== ov){
          ms = `I'm currently out of office, please contact ${this.$root.user.backupAdminName} at
                <a href='mailto:${this.$root.user.backupAdminEmail}'>${this.$root.user.backupAdminEmail}</a>
                 for assistance.`
        }
        return ms;
      }
    }
  }
</script>
<style lang="scss">
  @import "../scss/variables";
  @import '../../node_modules/bootstrap/scss/functions';
  @import '../../node_modules/bootstrap/scss/variables';
  @import '../../node_modules/bootstrap/scss/mixins';

  .leave-specialist {
    label {
      margin: 0;
    }

    .contact-info {
      .contact-data {
        word-break: break-all;
      }
    }

    p.warning {
      font-weight: 500;
      color: red;
      margin-top: 5px;
    }

    p.comment {
      font-weight: 300;
      font-style: italic;
      margin: 0;
    }

    .headshot {
      img {
        width: 96px;
      }

      margin-right: 24px;
    }

    .leave-admin-contact-info {
      margin-left: 0;

      .contact-label {
        label {
          font-size: initial;
        }

        max-width: 65px;
      }

      .contact-data {
        font-size: initial;
      }
    }

    title {
      font-weight: 700;
    }
  }

  @include media-breakpoint-down(sm) {
    .headshot {
      img {
        width: 60px !important;
      }
    }
  }
</style>