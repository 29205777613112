<template>
  <div class="welcome-video pb-3 pt-0 px-4">
    <h1>Welcome to Larkin Portal</h1>
    <p>{{ video.description }}</p>
    <iframe :src="video.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <b-btn type="button" to="/todo" class="btn-larkin-yellow btn-padded" v-if="getStartedButtonVisible">
      Get Started <i class="icon icon-arrow-right"></i>
    </b-btn>
  </div>
</template>

<script>
  export default {
    name: 'WelcomeVideo',
    data: function () {
      return {}
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    },
    props: {
      getStartedButtonVisible: {
        type: Boolean,
        default: true
      },
      video: {
        type: Object
      }
    }
  };
</script>

<style lang="scss">
  @import '../scss/modal-forms.scss';

  @import '../../node_modules/bootstrap/scss/functions';
  @import '../../node_modules/bootstrap/scss/variables';
  @import '../../node_modules/bootstrap/scss/mixins';

  .welcome-video {
    iframe {
      aspect-ratio: 16 / 9;
      margin-bottom: 1rem;
      min-width: 560px;
      width: 100%;
    }

    background: #FFFFFF;
    gap: 16px;
    text-align: center;
  }

  @include media-breakpoint-down(lg) {
    .welcome-video {
      iframe {
        min-width: 100%;
        width: 100%;
      }
    }
  }
</style>
