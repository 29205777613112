<template>
  <b-container fluid id="app" class="d-flex pl-0 pr-0 ml-0 mr-0 vh-100" v-bind:class="[ $route.meta.applyNewLook ? 'refresh-ui' : 'initial-ui', { 'nav-collapsed': !hideGlobalElements && collapseLeftNav }, {'nav-expanded': !hideGlobalElements && !collapseLeftNav}]">

    <!-- HEADER -->
    <b-navbar v-if="!hideGlobalElements"  class="main-header flex-column position-fixed w-100 p-0 m-0" :class="{'shrink':shrinkHeader, 'with-client-banner':(logoSrc)}">

      <div class="header-bar d-flex w-100">
        <!-- Header Title -->
        <div class="mr-auto header-title d-flex">
            <a @click="toggleLeftNavCollapse()" class="my-auto pl-4 d-md-flex">
              <HamCompressed v-if="navCollapsed" />
              <HamExtended v-if="!navCollapsed" />
            </a>
            <h4 class="page-title pl-3 my-auto font-weight-bold">{{displayTitle($route.meta.title)}}</h4>
        </div>

        <!-- Right Links -->
        <div class="align-items-start d-flex flex-row font-weight-bold header-right-links">
          <div class="client-logo my-auto"><img alt="img" :src="logoSrc" class="logo-image" /></div>
          <div v-if="!isEmployer" class="position-relative larkin-avatar">
            <b-link to="/home"><img alt="img" :src="adminAvatarSrc || '/img/admin-avatar.png'" />
            <div class="avatar-bubble-container">
<!--              <div class="avatar-bubble"><span> </span></div>-->
              <img class="avatar-bubble" src="../public/img/icons/admin-phone.svg" />
            </div>
            </b-link>
          </div>
        </div>
      </div>
    </b-navbar>
    <!-- END HEADER -->
    <SideNav v-if="!hideGlobalElements" v-bind:navCollapsed="collapseLeftNav" :toggleLeftNavCollapse="toggleLeftNavCollapse" />

    <main v-if="!hideGlobalElements" id="main" class="d-flex flex-column">
      <div id="body-content" class="body-content pb-3" :class="{'shrink':shrinkHeader, 'with-client-banner':(logoSrc)}">
        <SiteSurveys />
        <router-view/>
      </div>
      <footer class="footer-info text-center w-100 mt-auto">2024 The Larkin Company | <b-link to="/terms-of-service">Terms of Service</b-link> | <a href="https://www.thelarkincompany.com/privacy-policy/" target="_blank">Privacy Policy</a></footer>
    </main>

    <main v-else id="body-content">
      <router-view />
    </main>
    <footer v-if="hideGlobalElements" class="footer-info global-hidden text-center w-100">2024 The Larkin Company <span class="d-none d-md-inline">|</span> <br class="d-sm-inline d-md-none" /> <b-link to="/terms-of-service" target="_blank">Terms of Service</b-link> | <a href="https://www.thelarkincompany.com/privacy-policy/" target="_blank">Privacy Policy</a></footer>

  </b-container>
</template>

<script>
  // @ is an alias to /src
  import SideNav from '@/components/SideNav.vue'
  import SiteSurveys from '@/components/SiteSurveys.vue'
  import get from 'just-safe-get'
  import ClickToCallMain from "./components/ClickToCallMain";
  import breakpointMixin from '@/mixins/breakpointMixin.js';
  import HamExtended from "@/components/hamburger/HamExtended.vue";
  import HamCompressed from "@/components/hamburger/HamCompressed.vue";

  export default {
    components: {
      HamCompressed,
      ClickToCallMain,
      SiteSurveys,
      SideNav,
      HamExtended,
    },

    mixins: [breakpointMixin],

    data: function() {
      return {
        middleDot: "···",
        navCollapsed: false,
        scrollElement: null,
        shrinkHeader: false,
        defaultContactEmailUrl: "mailto:claims@thelarkincompany.com",
        isEmployer: false
      }
    },
    created() {
      if(this.isSmallOrLess()) this.toggleLeftNavCollapse()

      this.validateEmployee(this.$root.authStatus.userType)
    },
    watch: {
      breakpoint(val, oldVal) {
        if(val === "small" && !this.navCollapsed) {
          this.toggleLeftNavCollapse()
        }
      },
      '$root.authStatus.userType': function (val, oldVal) {
        console.log(val, oldVal)
        this.validateEmployee(val)
      }
    },
    methods: {
      toggleLeftNavCollapse() {
        this.navCollapsed = !this.navCollapsed
      },
      validateEmployee(val) {
        this.isEmployer = val !== "EMPLOYEE";
      },
      logout() {
        this.$root.logout();
      },
      /*eslint no-console: ["error", { allow: ["warn", "error"] }] */
      displayTitle: function(title) {
        let _this = this;

        if (title && title.indexOf('{') !== -1) {
          let arrayOfTokens = title.match(/{([^}]+)}/g);

          arrayOfTokens.forEach(function (token) {
            let indexFromToken = token.replace(/[{}]/g, '');

            if (_this.$root.user.hasOwnProperty(indexFromToken)) {
              title = title.replace(token, _this.$root.user[indexFromToken]);
            } else {
              console.error("ERROR: Missing index in user object property or waiting to resolve: [" + indexFromToken + "]");
              title = "";
            }
          });
        }
        return title;
      }
    },
    computed: {
      logoSrc() {
        return get(this.$root, 'user.employerLogo')!==undefined ? get(this.$root, 'user.employerLogo') : null;
      },

      collapseLeftNav() {
        return this.navCollapsed;
      },

      adminAvatarSrc() {
        return get(this.$root, 'leaveAdmin.avatar');
      },

      contactEmail() {
        let leaveAdminEmail = get(this.$root, 'user.leaveAdmin.email');
        return (leaveAdminEmail) ? "mailto:"+leaveAdminEmail : this.defaultContactEmailUrl;
      },

      hideGlobalElements() {
        return !!(get(this, '$route.meta.hideGlobalElements', false))
      }
    }
  }
</script>

<style lang="scss">
  @import "scss/app";

  .page-title {
    white-space: nowrap;
    padding-right: 70px;
  }

  body {
    main {
      @include transition(all .3s ease);
      transition: margin 300ms;
      background-color: $bodyBgColor;
      flex: 1;
      overflow-x: hidden;

      .body-content {
        margin-top: $navBarHeightAtBreakpoint;
        height: 100%;
        overflow: auto;

        &.nprogress-custom-parent {
          opacity: .5;
          #nprogress {
            .bar {}

            .spinner {
              top: 25%;
              right: 50%;

              .spinner-icon {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }

      a, a:hover {
        color: $bodyLinkColor;
      }

      .semi-bold {
        font-weight: 500;
      }

      .auth-container {
        .row:first-child {
          margin-left: 0;
          margin-right:0;
        }
      }
    }

    .main-header {
      z-index: 99;

      .header-bar {
        @include transition(all .3s ease);
        font-family: $navBarFont,sans-serif;
        background-color: $navBarBackground;
        height: $navBarHeight;
        border-bottom: 1px solid $navBarBorderColor;
        padding-left: $sideNavWidth;

        /* Page Header Title with Background Icon */
        .header-title {
          position: relative;

          .icon {
            color: $navBarIconColor;
          }
        }

        .logo-image {
          height: ($navBarHeight/1.2) * .5;
        }

        .larkin-avatar {
          width: 100px;

          img {
            position:absolute;
            top: 20px;
            left:10px;
            width:70px;
          }
        }

        .avatar-bubble-container {
          position: absolute;
          top: -8px;
          left: 72px;
          width: 50px;
          height: 35px;

          .avatar-bubble {
            position: relative;
            width: 25px;
          }
        }

        .header-right-links {
          @extend .pr-3;
          @extend .py-2;
        }
      }

      .larkin-logo-sm {
        img {
          width: 90px;
        }
      }

      .top-bar-sm {
        background-color: $sideNavBackGround;
        height: $navBarHeightAtBreakpoint;
      }
    }

    .nav-collapsed {
      main {
        margin: 0 0 0 $sideNavCollapsedWidth;
      }
      .main-header {
        .header-bar {
          overflow: visible;
          padding-left: $sideNavCollapsedWidth;
        }
      }
    }

    .nav-expanded {
      main {
        margin: 0 0 0 $sideNavWidth;
      }
    }

    .footer-info {
      color: #999;
      font-size: .7em;

      a {
        color: #999;
      }

      &.global-hidden {
        position:absolute;
        bottom:2px;
      }
    }

  }

  @include media-breakpoint-down(sm) {
    .client-logo {
      display: none;
    }
    .larkin-avatar {
      display: block;
    }

  }
  @include media-breakpoint-down(sm) {
    .nav-expanded {
      main {
        margin: 0 !important;
      }
    }
    .nav-collapsed {
      .sidenav {
        width: 0px !important;
      }
      main {
        margin: 0px !important;
      }
      .main-header {
        .header-bar {
          overflow: visible;
          padding-left: 0px !important;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    body {
      #main {

        .body-content {
          margin-top: $navBarHeight;
        }
      }

      .main-header {
        height: $navBarHeight;

        .header-bar {
          overflow: visible;
          padding-left: $sideNavWidth;

          .header-title {

            h1 {
              @include transition(all .3s ease);
              font-size: 44px;
              left: 185px;
            }
          }

          .header-right-links {
            @include transition(all .3s ease);
          }
        }
      }
    }
  }
</style>
