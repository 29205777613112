<template>
  <div class="home">
    <b-modal id="video-modal" body-class="overflow-auto" content-class="rounded-0" header-class="border-bottom-0" hide-footer size="lg">
      <WelcomeVideo v-if="displayedWelcomeVideo != null" :getStartedButtonVisible="welcomeVideoShowsGetStartedButton" :video="displayedWelcomeVideo" @close="dismissWelcomeModal" />
    </b-modal>
    <h3 class="pt-0 pb-2">Welcome!</h3>
    <p class="mb-4 card-text body-lg">We're here to ensure your leave of absence goes as smoothly as possible. This site is designed to help you successfully navigate your leave of absence and understand your pay.</p>
    <b-container fluid class="px-0 mb-5">
      <b-row align-v="stretch">
        <b-col lg>
          <LeaveAdminInfo class="h-100 leave-admin-info info-container-alt"/>
        </b-col>
        <b-col>
          <div class="h-100 getting-started info-container">
            <div class="info-icon pay-process-icon"></div>
            <h4 class="mb-2"><span class="icon icon-checkbox-checked2 green-check"></span> <span>First step: Complete your To Do List</span></h4>
            <p class="card-text" style="color: #616B6E !important;">You'll find everything you need to get your leave approved on the <b-link to="/todo">To Do List</b-link>.</p>
            <div class="text-center text-md-left">
              <b-btn to="/todo" class="btn-larkin-yellow btn-padded"><span class="button-text font-weight-bold" style="font-size: 14px;"> Get Started <i class="icon icon-arrow-right ml-2"></i> </span></b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <h4 class="pb-2">Getting started videos</h4>
    <b-container fluid class="videos mx-0 px-0">
      <b-row align-h="between">
        <b-col md v-for="(video, index) in videos">
          <div class="video-item">
            <b-link @click="showWelcomeVideo(video)">
              <iframe :src="video.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <h6 class="mb-1">Video {{ index + 1 }}: {{ video.title }}</h6>
              <p>{{ video.description }}</p>
            </b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import LeaveAdminInfo from '@/components/LeaveAdminInfo.vue'
  import WelcomeVideo from '@/components/WelcomeVideo.vue'
  import WelcomeVideoMixin from '@/mixins/welcomeVideoMixin.js'

  export default {
    name: 'home',
    components: {
      LeaveAdminInfo,
      WelcomeVideo
    },
    mixins: [WelcomeVideoMixin],
    data: function() {
      return {
        errors: [],
        welcomeVideoShowsGetStartedButton: false,
        displayedWelcomeVideo: null
      }
    },
    methods: {
      dismissWelcomeModal() {
        this.welcomeVideoShowsGetStartedButton = false;
        this.displayedWelcomeVideo = null;
        this.$bvModal.hide('video-modal');
      },
      incrementWelcomeVideoDisplayCountForUser(userId) {
        const userData = JSON.parse(localStorage.getItem(userId)) || {};
        const result = parseInt(userData['welcomeVideoDisplayCount'] || 0) + 1;
        userData['welcomeVideoDisplayCount'] = result;
        localStorage.setItem(userId, JSON.stringify(userData));
        sessionStorage.setItem('alreadyDisplayedWelcomeVideo', true);
      },
      showWelcomeVideo(video) {
        this.displayedWelcomeVideo = video;
        this.$bvModal.show('video-modal');
        this.incrementWelcomeVideoDisplayCountForUser(this.$root.user.id);
      }
    },
    mounted: function() {
      const alreadyDisplayedWelcomeVideo = sessionStorage.getItem('alreadyDisplayedWelcomeVideo');
      const userData = JSON.parse(localStorage.getItem(this.$root.user.id)) || {};
      const result = userData['welcomeVideoDisplayCount'];
      const displayWelcomeVideo = (result == null ? true : result < 2) && !alreadyDisplayedWelcomeVideo;
      // If we display the welcome video for a new user, the `Get Started` button should be shown, otherwise set
      // `welcomeVideoShowsGetStartedButton` to false so that it is not displayed when the user taps on a video from
      // the home page.
      this.welcomeVideoShowsGetStartedButton = displayWelcomeVideo;

      if (displayWelcomeVideo) {
        this.showWelcomeVideo(this.videos[0]);
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/refresh-ui.scss";
  @import "../scss/variables";
  @import '../../node_modules/bootstrap/scss/functions';
  @import '../../node_modules/bootstrap/scss/variables';
  @import '../../node_modules/bootstrap/scss/mixins';


  .home {
    background-color: #F7F5F2;
    padding: 1rem;
    padding-bottom: 3rem;
    padding-top: 40px !important;

    .leave-specialist {
      .comment-col {
        order: 3;
      }
    }

    h3 {
      span {
        vertical-align: middle;
      }
    }

    .leave-admin-info, .getting-started {
      justify-content: center;
      padding: 24px;
    }

    .info-container {
      .pay-process-icon {
        content: url("/img/pay/pay-process-icon.svg");
        margin-bottom: 16px;
      }

      background-color: #FFFFFF;
      flex-direction: column;
    }

    .videos {
      .video-item {
        a {
          color: #0E1F22;
        }

        a:hover {
          font-weight: revert;
          text-decoration: none;
        }

        iframe {
          aspect-ratio: 16 / 9;
          width: 100%;
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding: 3rem 1.5rem 0 1.5rem;
    }
  }


  #video-modal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .card-text {
    width: 75%;
  }

  .button-text {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include  media-breakpoint-down(sm) {
    .leave-admin-info, .getting-started {
      padding: 16px;
    }
    .card-text {
      width: 100%;
    }
    .home {
      padding-bottom: 40px;
    }
  }
</style>
