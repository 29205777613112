<template>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79051 4.202C5.07801 4.47132 5.07801 4.90799 4.79051 5.17731L1.77734 8L4.79051 10.8227C5.07801 11.092 5.07801 11.5287 4.79051 11.798C4.50301 12.0673 4.03687 12.0673 3.74937 11.798L0.215627 8.48766C-0.0718756 8.21833 -0.0718756 7.78167 0.215627 7.51234L3.74937 4.202C4.03687 3.93267 4.50301 3.93267 4.79051 4.202Z" fill="#0E1F22"/>
    <path d="M8.83434 1.45455H23.2638C23.6319 1.45455 24 1.13636 24 0.772727C24 0.409091 23.6319 0 23.2638 0H8.83434C8.42023 0 8.09814 0.363636 8.09814 0.727273C8.09814 1.09091 8.42023 1.45455 8.83434 1.45455ZM23.2638 7.27273H8.83434C8.42023 7.27273 8.09814 7.63636 8.09814 8C8.09814 8.40909 8.42023 8.72727 8.83434 8.72727H23.2638C23.6319 8.72727 24 8.40909 24 8C24 7.63636 23.6319 7.27273 23.2638 7.27273ZM23.2638 14.5455H8.83434C8.42023 14.5455 8.09814 14.9091 8.09814 15.2727C8.09814 15.6364 8.42023 16 8.83434 16H23.2638C23.6319 16 24 15.6818 24 15.3182C24 14.9545 23.6319 14.5455 23.2638 14.5455Z" fill="#0E1F22"/>
  </svg>
</template>

<script>
export default {
  name: 'HamExtended',
}

</script>