<template>
  <b-navbar id="sidenav" class="sidenav position-fixed p-0">
    <b-nav vertical class="flex-nowrap vh-100">
      <b-navbar-brand to="/" class="larkin-logo text-left">
        <img class="logo-lg" v-if="!navCollapsed" alt="Larkin" src="/img/logo/larkin-logo.svg" />
        <img v-if="navCollapsed" alt="Larkin" src="/img/logo/larkin-logo-sm.svg" />
      </b-navbar-brand>
      <ul class="flex-nowrap nav flex-column mt-3" v-if="$route.meta.showNavLinks">
        <b-nav-item @click="collapseIfMobile" to="/dashboard" v-if="navigationAllowed('dashboard')"><span class="bar" /> <span class="icon"><img src="/img/icons/icon-dashboard.svg" style="width: 22px"></span><span class="link-text">Dashboard</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile"  to="/active-leaves" v-if="navigationAllowed('activeLeaves')"><span class="bar" /> <span class="icon fa-icon-smaller"><i class="fa-regular fa-circle-check"></i></span> <span class="link-text">Active Leaves</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/employees" v-if="navigationAllowed('employees')"><span class="bar" /><i class="icon icon-employee"></i> <span class="link-text">Employees</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/reports" v-if="navigationAllowed('reports')"><span class="bar" /><i class="icon icon-reports"></i> <span class="link-text">Reports</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/compliance" v-if="navigationAllowed('compliance')"><span class="bar" /><i class="icon icon-compliance"></i> <span class="link-text">Compliance</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/benchmarking" v-if="navigationAllowed('benchmarking')"><span class="bar" /><i class="icon icon-benchmarking"></i> <span class="link-text">Benchmarking</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/home" v-if="navigationAllowed('home')"><span class="bar" /><i class="icon icon-home"></i> <span class="link-text">Home</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/todo" v-if="navigationAllowed('todo')"><span class="bar" /><i class="icon icon-grey-check"></i> <span class="link-text">To Do List</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/payments" v-if="navigationAllowed('payments')"><span class="bar" /><i class="icon icon-pay"></i> <span class="link-text">Payments</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/leaves" v-if="navigationAllowed('leaves')"><span class="bar" /><i class="icon icon-calendar"></i> <span class="link-text">Calendar</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/documents" v-if="navigationAllowed('documents')"><span class="bar" /><i class="icon icon-documents"></i> <span class="link-text">Documents</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/profile" v-if="navigationAllowed('employeeProfile')"><span class="bar" /><i class="icon icon-employee"></i> <span class="link-text">Profile</span></b-nav-item>
        <b-nav-item @click="collapseIfMobile" to="/payment" v-if="isShowingPayments() && navigationAllowed('paymentVouchers')"><span class="bar" /><i class="icon icon-pay"></i> <span class="link-text">Payments</span></b-nav-item>
        <b-nav-item v-if="!isGuest()" @click="logout()"><span class="bar" /><i class="icon icon-exit"></i> <span class="link-text">Logout</span></b-nav-item>
        <b-nav-item v-if="isGuest()" to="/login"><span class="bar" /><i class="icon icon-enter"></i> <span class="link-text">Login</span></b-nav-item>
      </ul>
    </b-nav>
  </b-navbar>
</template>

<script>
  import get from "just-safe-get";
  import navigationMixin from "@/mixins/navigationMixin.js";
  import breakpointMixin from "@/mixins/breakpointMixin";

  /**
   * TODO: ACL (Client vs EE nav)
   *
   * TODO: Show/Hide Links depending on state of EE's Leaves or Claims
   * We will need to deal with different nav items depending on the existance of the EE's leave or claim:
   * -- We dont show the to do list if the EE doesnt have any
   * -- We dont show leaves/claims if the EE doesnt have any
   * -- We dont show your docs if there are no documents
   */

  export default {
    name: 'SideNav',
    props: ['navCollapsed', 'toggleLeftNavCollapse'],
    mixins: [navigationMixin, breakpointMixin],
    methods: {
      collapseIfMobile() {
        console.log(this.isSmallOrLess())
        if(this.isSmallOrLess()) {
          this.toggleLeftNavCollapse();
        }
      },
      isGuest() {
        let userType = get(this.$root, 'authStatus.userType');
        return (userType==='GUEST')
      },

      isShowingPayments() {
        let disabilityClient = get(this.$root, 'user.disabilityClient');
        return disabilityClient;
      },

      logout() {
        this.$root.logout();
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/variables";
  @import '../../node_modules/bootstrap/scss/functions';
  @import '../../node_modules/bootstrap/scss/variables';
  @import '../../node_modules/bootstrap/scss/mixins';

  .logo-lg {
    height: 41px;
  }

  .sidenav {
    font-family: $sideNavFont,sans-serif;
    @include transition(all .3s ease);
    width: $sideNavWidth;
    min-height: $sideNavHeightAtBreakpoint;
    top:0;
    left:0;
    z-index: 99;
    background: $sideNavBackGround;
    font-size: 14px;
    overflow: hidden;

    .larkin-logo {
      display: flex;
      align-items: center;
      height: $navBarHeight;
      width: $sideNavWidth;
      background-color: $sideNavBackGround;
      img {
        margin-left: 20px;
      }
    }

    ul {
      width: $sideNavWidth;
    }

    a.nav-link {
      display: flex;
      padding: 12px 0 15px 0px;
      font-weight: 500;
      color: $sideNavFontColor;
      height:100%;
      align-items: center;
      height: 51px !important;

      span {
        margin-top: 3px;
      }
    }

    a.nav-link.router-link-active, .nav-link.router-link-active:hover {
      cursor: pointer;
      background-color: $sideNavActiveColor;
      //border-left: 4px solid $sideNavActiveLeftBorderColor;
      //border-radius: 4px;
      color: $sideNavFontActiveColor;

      .bar {
        background: $sideNavActiveLeftBorderColor;
        width: 3px;
        height: 36px;
        border-radius: 0px 2px 2px 0px;
        position: absolute;
      }

      .icon {
        color: $sideNavFontActiveColor;
        margin-left: 5px;
      }
    }

    a.nav-link:hover {
      cursor: pointer;
      background-color: $sideNavHoverBgColor;
      color: $sideNavHoverFontColor;

      .bar {
        background: $sideNavHoverLeftBorderColor;
        width: 3px;
        height: 36px;
        border-radius: 0px 2px 2px 0px;
        position: absolute;
      }

      .icon {
        color: $sideNavHoverFontColor;
        margin-left: 10px;
      }
    }

    .icon {
      color:$sideNavFontColor;
      font-size: 20px;
      width: 45px;
      text-align: center;
      margin-left: 5px;
    }

    .icon-smaller {
      font-size: 16px;
    }

    //.fa-icon-smaller {
    //  height: 20px;
    //}
  }
  .nav-collapsed {
    .page-title {
      white-space: normal;
      padding-right: 0px;
    }
  }

  .nav-collapsed .sidenav {
    width: $sideNavCollapsedWidth;

    .larkin-logo {
      padding-top: 0;
      img {
        margin-left: 20px;
      }
    }
    .a.nav-link {
      .icon {
        //margin-right: 5px;
      }
    }
  }
  .nav-collapsed .link-text {
      @include transition(all .3s ease);
      display: none;
  }

</style>
