import bsBreakpoints from 'bs-breakpoints';

export default {
  data: function() {
    return {
      breakpoint: null
    }
  },

  created: function () {
    bsBreakpoints.init();
    this.breakpoint = bsBreakpoints.detectBreakpoint();

    window.addEventListener('new.bs.breakpoint', this.updateBreakpoint);
  },

  methods: {
    updateBreakpoint: function() {
      this.breakpoint = bsBreakpoints.getCurrentBreakpoint();
    },

    isWithinBreakpointRange(rangeArray) {
      return (rangeArray.indexOf(this.breakpoint) !== -1);
    },

    isSmallOrLess: function() {
      return this.isWithinBreakpointRange(['xSmall', 'small'])
    },

    isMediumOrLess: function() {
      return this.isWithinBreakpointRange(['xSmall', 'small', 'medium'])
    },

    isSmallOrGreater: function() {
      return this.isWithinBreakpointRange(['small', 'medium', 'large', 'xLarge'])
    }

  }
}