import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VCalendar from './node_modules/v-calendar';
import App from './App.vue'
import {router, routing} from './router'
import axiosConfig from './axiosConfig'
import ApiProperties from '../../src/main/resources/application'
import Vuelidate from 'vuelidate'
import propertyUtils from './propertyUtils'
import browserDetect from "vue-browser-detect-plugin";
import NProgress from 'nprogress'
import Vue2Storage from 'vue2-storage'
import adminMixin from './mixins/adminMixin'
import linkify from 'vue-linkify'
import Constants from './plugins/constants'
import VueConfirmDialog from 'vue-confirm-dialog';

import '../node_modules/nprogress/nprogress.css'
import get from "just-safe-get";

/**
 * Setting some global defaults that can be referred to anywhere.
 */
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VCalendar);
Vue.use(Vuelidate);
Vue.use(browserDetect);
Vue.use(Vue2Storage);
Vue.directive('linkified', linkify)
Vue.use(Constants)
Vue.use(VueConfirmDialog);

const defaultAuthStatus = {
  portalUserId: null,
  state: null,
  userType: 'GUEST',
  authTypeRequired: 'LOGIN',
  csrfToken: null
};

const rootData = {
  authStatus: defaultAuthStatus,
  validation: {
    password: {
      regex: new RegExp('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,255})')
    }
  },
  user: {},
  properties: {
    uploadFileSizeLimit: propertyUtils.getFileSizeInBytes(process.env.VUE_APP_UPLOAD_FILESIZE_LIMIT)
  },
  leaveAdmin: {},
  trustDevice: !!localStorage.getItem('trustedAgentUuid'),
  trustedAgentUuid: localStorage.getItem('trustedAgentUuid'),
  intendedRoute: null
};

// we can assign higher level default path vars here if necessary
let axiosDefaultPathVars = {};

/**
 * Setup the axios instance with our defaults
 * @type {null|AxiosInstance}
 */
const axiosInstance = axiosConfig.setAxiosInstance(process.env.VUE_APP_AXIOS_BASE, true).axiosInstance;

//TODO: ADD THE ABILITY TO SAVE ROUTE INTENT SO WE CAN REDIRECT AFTER LOGIN

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.navigationAllowed && !record.meta.navigationAllowed(rootData))) {
    const loginRouteName = get(to, "meta.loginRouteName", Vue.prototype.$constants.LoginRouteNames.EMPLOYEE)
    const redirectRoute = routing.getDefaultRoute(rootData, loginRouteName)

    if (redirectRoute!==null && redirectRoute.name!== to.name) {
      rootData.intendedRoute = to
      next(redirectRoute)
      return;
    }
  }

  next();
})

router.afterEach((to, from) => {
  if (rootData.authStatus == null || rootData.authStatus.authTypeRequired === 'LOGIN') {
    window.dataLayer.push({
      'event': 'l_user_data',
      'l_event_id': crypto.randomUUID(),
      'l_logged_in': 0
    });
  } else {
    let isLoggedIn = 0;
    if (rootData.authStatus.authTypeRequired === 'NONE') {
      isLoggedIn = 1;
    }

    // Google analytics
    window.dataLayer.push({
      'event': 'l_user_data',
      'l_event_id': crypto.randomUUID(),
      'l_user_id': rootData.authStatus.portalUserId,
      'l_client_id': rootData.authStatus.contactId, // = client ID
      'l_user_type': rootData.authStatus.userType, // = EMPLOYEE, CLIENT (aka Benefit user), PAYROLL, etc.
      'l_logged_in': isLoggedIn
    });
  }
})

/**
 * Setup the nprogress bar to show up inside the specified container
 */

Vue.prototype.nprogress = NProgress.configure({ parent: '#body-content' });

/**
 * Setup the initialization error object (i.e. 500 or other on the authStatus call)
 *
 * @type {null}
 */
Vue.prototype.initError = null;

var vueInstance = null;

axiosInstance.get(ApiProperties.api.authStatus)
    .then(response => {
      rootData.authStatus = response.data;

      // add the authStatus object to possible pathvars
      axiosDefaultPathVars = Object.assign(axiosDefaultPathVars, {portalUserId: () => rootData.authStatus.portalUserId});

      // since we dont need the interceptors for the authStatus call, we can wait to set them here
      Vue.prototype.$http = axiosConfig.initAxiosInterceptors(() => rootData.authStatus.csrfToken, axiosDefaultPathVars, () => vueInstance).axiosInstance;
    })
    .then(() => {
      return rootData.authStatus.portalUserId ? axiosInstance.get(ApiProperties.api.user) : Promise.resolve({});
    })
    .then(response => {
      rootData.user = response.data ? response.data : {};
    })
    .catch(error => {
      // handle the error here from axios since this call happened pre interceptor init
      Vue.prototype.initError = error.response;

      //still need to attach axios to the root vue
      Vue.prototype.$http = axiosConfig.initAxiosInterceptors(() => rootData.authStatus.csrfToken, axiosDefaultPathVars, () => vueInstance).axiosInstance;
    })
    .finally(() => {
      vueInstance = new Vue({
        router,
        render: h => h(App),
        data: rootData,
        mixins: [adminMixin],
        methods: {
          logout() {
            this.$http.post(ApiProperties.api.logout)
              .then(() => {
                this.clearState();
            }).catch((e) => {
                console.log(`Error logging out: ${e}`)
              })
            },
          clearState() {
            const oldUserTypeAsRootData = {
              authStatus: {
                userType: get(this, 'authStatus.userType', null)
              }
            }
            let loginRouteName = (routing.isEmployee(oldUserTypeAsRootData)) ? this.$constants.LoginRouteNames.EMPLOYEE : this.$constants.LoginRouteNames.CLIENT
            this.authStatus = defaultAuthStatus;
            this.user = {};
            this.navigateToDefaultRoute(loginRouteName);
          },
          navigateToMaintenance() {
            this.authStatus = defaultAuthStatus;
            this.user = {};
            this.$router.push({ name: 'maintenance' }).catch(err => {});
          },
          updateAuthStatus(newAuthStatus) {
            if (newAuthStatus) {
              this.authStatus = newAuthStatus;
              return;
            }

            return this.$http.get(ApiProperties.api.authStatus)
                .then(response => {
                  this.authStatus = response.data;
                });
          },
          fetchUser() {
            if (!this.authStatus.portalUserId) {
              this.user = {};
              return Promise.resolve(this.user);
            }

            return this.$http.get(ApiProperties.api.user)
              .then(response => {
                this.user = response.data;
                return this.user;
              });
          },
          getRouteByName(routeName) {
            return routing.routesByName[routeName];
          },
          navigateToDefaultRoute(loginRouteName) {
            const redirect = routing.getDefaultRoute(rootData, loginRouteName)
            this.$router.push(redirect)
          },
          setRootLeaveAdminInfo() {
            if (routing.isFullyAuthenticatedEmployee(rootData)) {
              this.setLeaveAdminInfo(rootData)
            }
          }
        },
        watch: {
          authStatus: function(newAuthStatus, oldAuthStatus) {
            if (!newAuthStatus.portalUserId) {
              this.user = {};
              this.resetLeaveAdmin(rootData)
              return;
            }

            if (newAuthStatus.agentUuid) {
              localStorage.setItem('trustedAgentUuid', newAuthStatus.agentUuid);
              this.$root.trustedAgentUuid = newAuthStatus.agentUuid;
              this.$root.trustDevice = true;
            }

            // Google analytics
            if (newAuthStatus.authTypeRequired === 'NONE' && oldAuthStatus.authTypeRequired !== 'NONE') {
              window.dataLayer.push({
                'event': 'l_login',
                'l_event_id': crypto.randomUUID(),
                'l_method': 'Site',
                'l_user_id': newAuthStatus.portalUserId,
                'l_client_id': newAuthStatus.contactId, // = client ID
                'l_user_type': newAuthStatus.userType, // = EMPLOYEE, CLIENT (aka Benefit user), PAYROLL, etc.
              });
            }

            if (newAuthStatus.portalUserId !== oldAuthStatus.portalUserId) {
              this.fetchUser().then(() => {
                this.setRootLeaveAdminInfo()
                this.navigateToDefaultRoute()
              });
            } else {
              this.setRootLeaveAdminInfo()
            }
          },
          trustDevice: function(newTrustDevice, oldTrustDevice) {
            if (! newTrustDevice) {
              this.$root.trustedAgentUuid = null;
              localStorage.removeItem('trustedAgentUuid');
            }
          }
        },
        created: function() {
          // needed to reload admin info on a hard refresh
          this.setRootLeaveAdminInfo()
        }

      }).$mount('#app');

      //TODO: change this to a health check and 500 errors will no longer route here
      if (vueInstance.initError && vueInstance.initError.status === 500) {
        vueInstance.navigateToMaintenance();
      }
  });
